<template>
  <div class="account">
    <msp-app-bar title="Consensi"></msp-app-bar>
    <v-container>
      <v-row dense v-if="utente">
      <v-col
          cols="12"
          sm="6"
          v-for="field in fields"
          :key="field.key"
        >
        <v-switch
            v-model="utente[field.key]"
            :label="field.label"
            true-value="S"
            false-value="N"
            @change="updateField(field)"
        ></v-switch>
        </v-col>

</v-row>
    </v-container>
</div>

</template>

<script type="module">
import mspAppBar from "@components/MspAppBar.vue";
import env from "@mixins/environment.js"
import _api from "@mixins/api.js";
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";

const component = {
  mixins: [
    env,
    allenamentoCalcoli,
  ],
  components: {
    mspAppBar,

  },
  data () {
    return {
        maxDate: null,
        minDate: null,
        utente: null,
        fields: [
            {
                "key": "flg_invioEmailAllenamento",
                "label": "Ricezione email quotidiana dell'allenamento del giorno",
            }, 
            {
                "key": "flg_invioReportPostAllenamento",
                "label": "Ricezione email del report post allenamento",
            }, 
            {
                "key": "flg_invioEmailAggiornamenti",
                "label": "Ricezione email relative a aggiornamenti software e comunicazioni di servizio",
            }, 
             
        ],
    }
  },
  mounted () {
    this.init();

  },
  watch: {
    },
  computed: {
  },
  methods: {
      init: function () {
        this.loadConsensi().then(
          (result)=>{
            if (result.success) {
              this.utente = {...result.data};
            }
          }
        );

      },
    updateField: function (field) {
            let data = {
                [field.key]: this.utente[field.key],
            }
            this.updateConsensi(data);
    },

    updateConsensi: function (data) {
        let url = this.api_base_url+"/api/v2/utente.php";//+params;
        return new Promise((resolve,reject)=>{
            _api(url, "PUT", data)
                .then((result)=>{
                    if (result.success) {
                        resolve(result);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error)=>{
                    reject(error);
                });
        });
    },

    loadConsensi: function () {
        let url = this.api_base_url+"/api/v2/utente.php";//+params;

        return new Promise((resolve,reject)=>{
            _api(url, "GET")
                .then((result)=>{
                    if (result.success) {
                        resolve(result);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error)=>{
                    reject(error);
                });
        });
    },

  },
}
export default component
</script>
<style lang="scss">
.account {
}
</style>
